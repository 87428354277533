<template>
  <div>
    <form v-if="form" class="mt-5" @submit.prevent="submit">
      <b-field :label="$tc('product_type')" label-for="product" horizontal>
        <b-select id="product" v-model="form.product" required>
          <option v-for="p in products" :key="p.id" :value="p['@id']">
            {{ p.name }}
          </option>
        </b-select>
      </b-field>

      <CustomInput
        v-model="form.serialNumber"
        :label="$t('serial_number')"
      ></CustomInput>

      <b-field
        v-if="mode === 'create'"
        :label="$t('status')"
        label-for="status"
        horizontal
      >
        <b-select id="status" v-model="form.status" required>
          <option v-for="d in deviceStatuses" :key="d.id" :value="d['@id']">
            {{ d.name | ucFirst }}
          </option>
        </b-select>
      </b-field>

      <CustomDatePicker
        v-model="form.manufacturingDate"
        :label="$t('manufacturing_date')"
      ></CustomDatePicker>

      <CustomInput
        v-model="form.manufacturingCosts"
        :label="$t('manufacturing_costs')"
        :type="'number'"
        :icon="'currency-eur'"
      ></CustomInput>

      <CustomDatePicker
        v-model="form.deliveryDate"
        :label="$t('delivery_date')"
      ></CustomDatePicker>

      <CustomMonthDatePicker
        v-model="form.arcsRenewalDate"
        :label="$t('arcs_renewal_date')"
        placeholder="mm-dd"
      ></CustomMonthDatePicker>

      <CustomInput
        v-model="form.xtSoftwarePassword"
        :label="$t('xt_software_password')"
      ></CustomInput>

      <CustomInput
        v-model="form.xtSoftwareVersion"
        :label="$t('xt_software_version')"
      ></CustomInput>

      <CompanySelect
        v-if="mode === 'create'"
        v-model="form.systemOwner"
        :label="$t('system_owner')"
        :companies="companies"
        :horizontal="true"
      ></CompanySelect>

      <CustomDatePicker
        v-model="form.lastCalibrationDate"
        :label="$t('calibration')"
      ></CustomDatePicker>

      <CustomDatePicker
        v-model="form.calibrationExpirationDate"
        :label="$t('calibration_expiration')"
      ></CustomDatePicker>

      <b-button
        type="is-primary"
        native-type="submit"
        class="mt-5"
        icon-left="content-save"
        :loading="loading"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import CompanySelect from "../../../components/forms/CompanySelect";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";
import CustomInput from "../../../components/forms/CustomInput";
import CustomMonthDatePicker from "../../../components/forms/CustomMonthDatePicker";

export default {
  components: {
    CompanySelect,
    CustomInput,
    CustomMonthDatePicker,
    CustomDatePicker,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    device: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        product: this.device?.product["@id"],
        serialNumber: this.device?.serialNumber,
        manufacturingDate: this.device?.manufacturingDate,
        manufacturingCosts: this.device?.manufacturingCosts,
        deliveryDate: this.device?.deliveryDate,
        arcsRenewalDate: this.device?.arcsRenewalDate,
        xtSoftwarePassword: this.device?.xtSoftwarePassword,
        xtSoftwareVersion: this.device?.xtSoftwareVersion,
        lastCalibrationDate: this.device?.lastCalibrationDate,
        calibrationExpirationDate: this.device?.calibrationExpirationDate,
      },
      products: [],
      companies: [],
    };
  },
  computed: {
    ...mapState({
      deviceStatuses: (state) => state.device.deviceStatuses,
    }),
  },
  watch: {
    "form.manufacturingDate"(newValue, oldValue) {
      if (oldValue) {
        return;
      }
      this.form.deliveryDate = newValue;
      this.form.arcsRenewalDate = dayjs(newValue).format("M-D");
      this.form.lastCalibrationDate = newValue;
    },
    "form.lastCalibrationDate"(value) {
      if (!value) {
        this.form.calibrationExpirationDate = null;
        return;
      }
      this.form.calibrationExpirationDate = dayjs(value)
        .add(1, "year")
        .format("YYYY-MM-DD");
    },
  },
  async mounted() {
    this.products = await this.getAllProducts();

    if (this.mode === "create") {
      this.form = {
        ...this.form,
        product: this.products[0]["@id"],
        status: this.deviceStatuses[0]["@id"],
        systemOwner: null,
      };

      this.companies = await this.getAllCompanies({ blocked: false });
    }
  },
  methods: {
    ...mapActions(["getAllProducts", "getAllCompanies"]),
    submit() {
      this.$emit("submit", {
        ...this.form,
        manufacturingDate: dayjs(this.form.manufacturingDate).format(
          "YYYY-MM-DD"
        ),
        manufacturingCosts: this.form.manufacturingCosts.toString(),
        deliveryDate: dayjs(this.form.deliveryDate).format("YYYY-MM-DD"),
        lastCalibrationDate: dayjs(this.form.lastCalibrationDate).format(
          "YYYY-MM-DD"
        ),
        calibrationExpirationDate: dayjs(
          this.form.calibrationExpirationDate
        ).format("YYYY-MM-DD"),
      });
    },
  },
};
</script>
