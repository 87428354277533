<template>
  <b-field
    class="fooo"
    :horizontal="horizontal"
    :label="label"
    :label-for="id || _uid.toString()"
  >
    <b-select v-model="selectedDay" required :placeholder="$t('day')">
      <option v-for="d in days" :key="d" :value="d">
        {{ d }}
      </option>
    </b-select>
    <b-select
      :id="id || _uid.toString()"
      v-model="selectedMonth"
      required
      :placeholder="$t('month')"
    >
      <option v-for="m in months" :key="m.index" :value="m">
        {{ $t(`month_${m.index}`) }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDay: null,
      selectedMonth: null,
      months: [
        { index: 1, days: 31 },
        { index: 2, days: 28 },
        { index: 3, days: 31 },
        { index: 4, days: 30 },
        { index: 5, days: 31 },
        { index: 6, days: 30 },
        { index: 7, days: 31 },
        { index: 8, days: 31 },
        { index: 9, days: 30 },
        { index: 10, days: 31 },
        { index: 11, days: 30 },
        { index: 12, days: 31 },
      ],
    };
  },
  computed: {
    days() {
      return Array.from(
        { length: this.selectedMonth ? this.selectedMonth.days : 31 },
        (_, i) => i + 1
      );
    },
  },
  watch: {
    value() {
      this.setInitialValues();
    },
    selectedMonth() {
      if (this.selectedDay > this.days.length) {
        this.selectedDay = this.days.length;
      }
      this.emit();
    },
    selectedDay() {
      this.emit();
    },
  },
  mounted() {
    this.setInitialValues();
  },
  methods: {
    setInitialValues() {
      if (this.value) {
        const [monthIndex, day] = this.value.split("-").map((s) => parseInt(s));
        this.selectedDay = day;
        this.selectedMonth = this.months.find((m) => m.index === monthIndex);
      }
    },
    emit() {
      if (!this.selectedDay || !this.selectedMonth) {
        return;
      }
      const str = `${this.selectedMonth.index}-${this.selectedDay}`;
      if (this.value === str) {
        return;
      }
      this.$emit("input", str);
    },
  },
};
</script>
